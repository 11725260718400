export const HerbicideList = [
  {
    _id: 1,
    Name: "Sunfit",
    imgurl: "ProductImg/Sunfit.png",
    TechnicalName: "Pretilachlor 50% E.C",
    Type: "Selective Herbicide",
    Crops: "Paddy.",
    Packing: "1Ltr, 500ml, 250ml.",
    Usage:
      "Controls grasses,sedges &some broad leaf weeds in transplanted Rice.",
  },
  {
    _id: 2,
    Name: "Sun Quit",
    imgurl: "ProductImg/SunQuit.png",
    TechnicalName: "Paraquate Dichloride 24% SL",
    Type: "Non-Selective Contact Herbicide",
    Crops: "Non crop area.",
    Packing: "1Ltr, 500ml, 250ml.",
    Usage: "controls weeds in many agricultural & non-agricultural settings.",
  },
  {
    _id: 3,
    Name: "Clear-71",
    imgurl: "ProductImg/Clear.png",
    TechnicalName: "Ammonium salt of Glyphosate 71% SG",
    Type: "Systemic Herbicide",
    Crops: "Non crop area.",
    Packing: "100gm.",
    Usage: "Controls annual, perennial, broad leaf and grassy weeds. ",
  },
  {
    _id: 4,
    Name: "Allout",
    imgurl: "ProductImg/Allout.png",
    TechnicalName: "2-4D Amine salt 58 % SL",
    Type: " Selective Herbicide",
    Crops: "Sorghum, Maize, Wheat, Potato, Sugarcane.",
    Packing: "1Ltr, 400ml.",
    Usage:
      "Controls broad leaved weeds in cropped area as well as in Non cropped areas.",
  },
  {
    _id: 5,
    Name: "Runout",
    imgurl: "ProductImg/Runout.png",
    TechnicalName: "2-4, D-Ethyl Ester 38 % EC",
    Type: " Selective Herbicide",
    Crops: "Sorghum, Maize, Wheat, Potato, Sugarcane.",
    Packing: "1Ltr, 500ml, 250ml.",
    Usage:
      "Controls broad leaved weeds in cropped area as well as in Non cropped areas.",
  },

  {
    _id: 6,
    Name: "Sunkill",
    imgurl: "ProductImg/Sunkill.png",
    TechnicalName: "Metribuzin 70% W.P.",
    Type: " Selective,Systemic and Contact Herbicide",
    Crops: "Wheat, Potato, Soybean, Sugarcane.",
    Packing: "100gm, 50gm.",
    Usage: "Act as pre & post-emergence herbicide in various crops.",
  },
  {
    _id: 7,
    Name: "Gladiator",
    imgurl: "ProductImg/Gladiator.png",
    TechnicalName: "Tembotrione 34.4% S.C.",
    Type: " Selective,Systemic and Contact Herbicide",
    Crops: "Sugarcane, Maize.",
    Packing: "115ml.",
    Usage:
      "Recommended for use along with surfactant for control of broad leaf and grassy weeds.",
  },
];
